@font-face {
  font-family: "CodeNext";
  font-display: swap;
  src: url("../fonts/CodeNextBold.woff2") format("woff2"),
  url("../fonts/CodeNextBold.woff") format("woff");
  font-weight: bold;
}
@font-face {
  font-family: "CodeNext";
  font-display: swap;
  src: url("../fonts/CodeNextBook.woff2") format("woff2"),
  url("../fonts/CodeNextBook.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "CodeNext";
  font-display: swap;
  src: url("../fonts/CodeNextSemiBold.woff2") format("woff2"),
  url("../fonts/CodeNextSemiBold.woff") format("woff");
  font-weight: 600;
}



body {
  font-family: 'CodeNext';
}
